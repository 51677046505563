@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf);
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf);
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf);
  font-weight: 100;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf);
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf);
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-Black.ttf);
  font-weight: 900;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url(../fonts/Source_Sans_Pro/SourceSansPro-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Fjalla_One';
  src: url(../fonts/Fjalla_One/FjallaOne-Regular.ttf);
}