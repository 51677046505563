$min-sm: 576px;
$min-md: 768px;
$min-lg: 992px;
$min-xl: 1200px;
$min-xxl: 1400px;

$max-xs: $min-sm - 1px;
$max-sm: $min-md - 1px;
$max-md: $min-lg - 1px;
$max-lg: $min-xl - 1px;
$max-xl: $min-xxl - 1px;

@mixin min-sm {
  @media (min-width: $min-sm) {
    @content;
  }
}
@mixin min-md {
  @media (min-width: $min-md) {
    @content;
  }
}
@mixin min-lg {
  @media (min-width: $min-lg) {
    @content;
  }
}
@mixin min-xl {
  @media (min-width: $min-xl) {
    @content;
  }
}
@mixin min-xxl {
  @media (min-width: $min-xxl) {
    @content;
  }
}

@mixin max-xs {
  @media (max-width: $max-xs) {
    @content;
  }
}
@mixin max-sm {
  @media (max-width: $max-sm) {
    @content;
  }
}
@mixin max-md {
  @media (max-width: $max-md) {
    @content;
  }
}
@mixin max-lg {
  @media (max-width: $max-lg) {
    @content;
  }
}
@mixin max-xl {
  @media (max-width: $max-xl) {
    @content;
  }
}