@import '_colors';
@import '_fonts';
@import '_mediaQueries';

* {
  font-family: 'Source Sans Pro', arial, sans-serif;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

a {
  color: $link-color;
  text-decoration: underline;
}

.App {
  .navbar {
    .navbar-brand {
      font-weight: bold;
      color: $primary;
      text-decoration: none;
      .logo {
        width: 200px;
        transition: width 0.5s ease 0s;
        &.headerLarge {
          width:320px;
        }
      }
    }
    .nav-item {
      padding: 8px;
      font-size: 18px;

      .nav-link {
        color: #000;
        padding: 0 0 3px;
        text-decoration: none;

        &:hover {
          color: $primary-dark;
        }
        &:active {
          color: $primary;
        }
      }
      
      &.active {
        .nav-link {
          color: $primary;
          font-weight: bold;
          border-bottom: 1px solid $primary;
        }
      }
    }
  }

  .page-header {
    p {
      font-size: 25px;
    }
  }
  .page-bg-row {
    background-size: cover;

    h3, p {
      color: #fff;
      text-shadow: 0px 0px 4px black;
    }

    p {
      font-size: 20px;
    }
    
    &.bg-contact-us {
      background-image: url('../images/contact-us.jpg');
      background-position-y: 36%;
    }

    &.bg-subscribe {
      background-image: url('../images/using-laptop.jpg');
      background-position-y: 36%;
      
      .flex-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;

        @include max-md {
          flex-wrap: wrap;
        }

        .form-group {
          margin-right: 30px;

          @include max-md {
            margin: 0;
          }
        }
        
        input, .form-group {
          max-width: 100%;
          width: 600px;

          @include max-md {
            width: 100%;
          }
        }

        button {
          @include max-md {
            width: 100%;
          }
        }

        input, button {
          height: 50px;
        }
      }
    }
  }

  form {
    .form-label {
      font-weight: bold;
    }
    .no-margin {
      margin: 0 !important;
    }
  }
    
    .btn {
      font-size: 15px;
      padding: 10px 30px;
      border-radius: 0;
      background-color: $primary;
      color: #fff;
      border: none;
      text-decoration: none;

      @include max-xs {
        font-size: 10px;
        padding: 5px 15px;
      }
      
      &:hover {
        background-color: $primary-light;
      }
      &:focus {
        border: none;
        box-shadow: none;
      }

      &.btn-link {
        padding: 0;
        background: none;
        @extend a;
      }
    }

    .row {

      h3 {
        color: $primary;
        font-size: 40px;
        font-family: "Fjalla One", arial, sans-serif;

        &.color-white {
          color: #fff;
        }
      }

      h4 {
        margin-bottom: 30px;
        font-size: 22px;
        font-weight: normal;
      }

      .small-hr {
        width: 50px;
        margin-left: 0;
        border-width: 2.3px;
        margin-bottom: 45px;

        &.center {
          margin-left: auto;
          margin-right: auto;
        }
        &.color-primary {
          border-color: $primary;
        }
        &.color-primary-light {
          border-color: $primary-light;
        }
        &.color-primary-dark {
          border-color: $primary-dark;
        }
      }
    }
    .carousel {
      // margin-bottom: 45px;

      .carousel-indicators { 
        @include max-xs {
          display: none;
        }
      }

      .carousel-caption {
        text-align: left;
        bottom: 28%;
        left: 30px;
        right: 25%;

        @include max-lg {
          bottom: 6%;
        }
        @include max-md {
          bottom: 20%;
        }
        @include max-sm {
          bottom: 10%;
        }
        @include max-xs {
          bottom: 5px;
          right: auto;
        }
        
        h2 {
          font-family: "Fjalla One", arial, sans-serif;
          font-size: 37px;
          line-height: 1.4em;
          margin-bottom: 20px;
          text-shadow: 0px 0px 4px black;
          // text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;  // 1px text border

          @include max-md {
            font-size: 25px;
          }
          @include max-sm {
            font-size: 18px;
          }
          @include max-xs {
            font-size: 12px;
            margin-bottom: 5px;
          }
        }
      }
    }
    .flex-image-container {
      position: relative;

      .col,
      .col-xs,
      .col-sm,
      .col-md,
      .col-lg,
      .col-xl,
      .col-xxl {
        position: initial;
      
        .flex-image {

          @include max-sm {
            display: none;
          }

          &.right-half {
            position: absolute;
            right: 0;
            left: 50%;
          }
          &.left-half {
            position: absolute;
            left: 0;
            right: 50%;
          }
        }
      }
      .text-col {
        @include min-md {
          max-width: 500px;
        }
      }

      
      &.what-we-do {
          .flex-image {
            background-image: url('../images/runner.jpg');
            background-position: left;
          }
      }

    }

    .flex-image {
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .staff-col {
      padding-bottom: 45px;
      .headshot {
        max-width: 250px;
        margin-bottom: 20px;
      }
      h4.staff {
        font-size: 26px;
      }
    }
  }
  .footer {
    .navbar {
      .container {
        flex-direction: column;
      }
    }
  }

div.spacer10 {
  height: 10px;
}
div.spacer15 {
  height: 15px;
}
div.spacer30 {
  height: 30px;
}
div.spacer45 {
  height: 45px;
}
div.spacer60 {
  height: 60px;
}
div.spacer90 {
  height: 90px;
}
div.spacer120 {
  height: 120px;
}

.margin-bottom-75 {
  margin-bottom: 75px;

  @include max-sm {
    margin-bottom: 20px;
  }
}