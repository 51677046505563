$primary: #00A4D0;
$primary-light: #6bdfff;
$primary-dark: #305B74;

$link-color: #15c;

.bg-white {
  background-color: #fff !important;
}
.bg-gray {
  background-color: rgb(193, 197, 206) !important;
}
.bg-dark {
  background-color: #373a40 !important;
}
.bg-primary {
  background-color: $primary !important;
}
.bg-primary-light {
  background-color: $primary-light !important;
}
.bg-primary-dark {
  background-color: $primary-dark !important;
  color: #fff;
}